import React from "react"

const BadgeComponent = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
    }}
  >
    <link
      rel="stylesheet"
      href="https://cdn6.localdatacdn.com/badges/restaurantji/css/circle_v5.css?v=95292"
    />
    <div
      id="circle_v5_wrap"
      style={{ width: "calc(250px * 1)", height: "calc(250px * 1)" }}
    >
      <div id="circle_v5" tabIndex="0">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.restaurantji.com/ct/milford/dockside-brewery-/"
          id="circletype_v5_brand_name"
          className="autoFontSize"
          style={{ fontSize: "calc(13px * 1)" }}
        >
          <div className="ahref_emprty_area">Dockside Brewery</div>
          <hr id="restaurantji_badge_separator" />
          <div
            className="circle_badge_category"
            style={{ fontSize: "calc(13px * 1)" }}
          >
            Brewery
          </div>
        </a>
        <a
          href="https://www.restaurantji.com/ct/milford/"
          target="_blank"
          rel="noopener noreferrer"
          id="rewards_link"
        >
          Restaurantji
        </a>
      </div>
      <div
        style={{
          clip: "rect(0 0 0 0)",
          WebkitClipPath: "inset(50%)",
          clipPath: "inset(50%)",
          height: "1px",
          overflow: "hidden",
          position: "absolute",
          whiteSpace: "nowrap",
          width: "1px",
        }}
      >
        Dockside Brewery{" "}
        <div>
          <span>is a must-visit according to Restaurantji</span>{" "}
          <span>- your go-to source for the best local restaurants.</span>
        </div>
      </div>
    </div>
  </div>
)

export default BadgeComponent
